<template>
  <v-app id="inspire">
    <!-- URL 변경 공지 추가 -->
    <v-card-text class="pt-4 pb-0">
      <div class="d-flex align-center mb-2">
        <v-icon color="info" class="mr-2">info</v-icon>
        <span class="text-body-2">공지사항:</span>
      </div>
      <div class="d-flex align-center mb-2">
        <span class="text-body-2">
          서비스 URL이
          <a href="https://aml-trainer.kakaoi.io/" target="_blank" class="info--text text-decoration-underline">https://aml-trainer.kakaoi.io/</a>
          에서
          <a href="https://aml-trainer.kakao.com/" target="_blank" class="info--text text-decoration-underline">https://aml-trainer.kakao.com/</a>
          로 변경됩니다.
        </span>
      </div>
      <div class="d-flex align-center mb-2">
        <span class="text-body-2">현재 URL은 2025년 4월 30일까지 운영됩니다.</span>
      </div>
    </v-card-text>
    <router-view></router-view>
    <confirm-dialog />
  </v-app>
</template>

<script>
import { getCookie } from '@/utils'

export default {
  watch: {
    '$route' () {
      if (this.$router.currentRoute.name != 'login') {
        const token = getCookie('x-access-token')
        if (!token) {
          this.$router.push({ name: 'login' })
        }
      }
    }
  }
}
</script>
