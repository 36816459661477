<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm6 md4 lg4 xl2>
          <v-form v-on:submit.prevent="triggerToKakaoAuth">
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Trainer Admin Tool</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                  <v-text-field
                    v-model="customer"
                    prepend-icon="company"
                    name="login"
                    label="법인"
                    type="text"
                  >
                  </v-text-field>
              </v-card-text>
              <v-card-actions>
                <!-- {{ "해당 번호를 담당자에게 전달해 주 세요. :" + msg }} -->
                <v-spacer></v-spacer>
                <!-- <v-btn
                  color="primary"
                  @click="login"
                >
                  Login
                </v-btn> -->
                <KakaoLogin
                  ref="kakaologin"
                  api-key="559e22f0f34a41fb52f7d2b709448b66"
                  image="kakao_login_btn_medium"
                  :on-success=onSuccess
                  :on-failure=onFailure
                >
                </KakaoLogin>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-flex>
      </v-layout>
    </v-container>
    <!-- common dialog -->
    <common-dialog
      :title="'알림'"
      :icon="'info'"
      :message="commonDialog['message']"
      :dialog="commonDialog['isShow']"
      @close="commonDialog['isShow'] = false"
    ></common-dialog>

  </v-main>
</template>

<script>
// import API from '@/api'
import KakaoLogin from 'vue-kakao-login'
import { setCookie, getCookie } from '@/utils'
import CommonDialog from '@/components/common/CommonDialog.vue'


export default {
  name : "LoginContainer",
  components: {
    KakaoLogin,
    CommonDialog
  },
  created () {
    // this.verifyToken()
  },
  data () {
    return {
      msg: '',
      userid: '',
      passwd: '',
      customer: '',
      commonDialog: {
        isShow: false,
        title: '',
        message: '',
        statusCode: null,
      },
      formDialog: {
        isShow: false,
      },
      form: {
        requestOperator: 3,
        description: '',
      },
    }
  },
  methods: {
    triggerToKakaoAuth(){
      this.$refs.kakaologin.$el.click();

    },
    onSuccess (data) {
      // console.log (data)
      let access_token = data['access_token']
      this.$http.post(process.env.VUE_APP_ACCESS_TOKEN_CONFIRM, {
        'access_token': access_token,
        'customer': this.customer,
      }).then(res => {
        let data = res.data
          // console.log('token :', data['data']['token'])
          setCookie('x-access-token', data['data']['token'], 1)
          this.$http.defaults.headers['Authorization'] = getCookie('x-access-token')
          this.$router.push({ name: 'train_data'})

        if (data.status === -1) {
          // 승인 절차가 필요함
          alert('승인이 필요합니다.')
          this.commonDialog['isShow'] = true
          this.commonDialog['title'] = data.customer
          this.commonDialog['message'] = 'app id: ' + data.id
          // this.msg = data.id
        } else if (data.status == 0) {
          alert('승인절차가 진행중입니다.')
          this.commonDialog['isShow'] = true
          this.commonDialog['title'] = data.customer
          this.commonDialog['message'] = 'app id: ' + data.id
        }
      }).catch(error => {
        const status = error.status
        this.commonDialog['statusCode'] = status
        this.passwd = ''
        switch (status) {
          case 400:
            this.commonDialog['title'] = '로그인 실패'
            this.commonDialog['message'] = '아이디 또는 비밀번호를 확인해주세요.'
            this.commonDialog['isShow'] = true
            break
          case 403:
            this.commonDialog['title'] = '알림'
            this.commonDialog['message'] = '승인 진행중 입니다.'
            this.commonDialog['isShow'] = true
            break
          case 404:
            this.commonDialog['title'] = '알림'
            this.commonDialog['message'] = '승인 요청메시지가 관리자에게 전달되었습니다.'
            this.commonDialog['isShow'] = true
            break
          case 406:
            this.commonDialog['title'] = '알림'
            this.commonDialog['message'] = '법인명 입력이 잘못되었습니다.'
            this.commonDialog['isShow'] = true
            break
          default:
            this.commonDialog['title'] = '서버 오류'
            this.commonDialog['message'] = '서버와 연결이 끊겼습니다.'
            this.commonDialog['isShow'] = true
            break
        }
      })
    },
    onFailure (data) {
      console.log(data)
    },
    login () {

    },
    verifyToken () {
    },
    commonDialogConfirm () {
      this.commonDialog['isShow'] = false
      if (this.commonDialog['statusCode'] === 404) {
        this.formDialog['isShow'] = true
      }
    },
    createUser () {

    },
  }
}
</script>